/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowPathIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import GoogleFontLoader from 'react-google-font'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { FaUndo } from 'react-icons/fa'

// Icons
import { EditIcon } from '../../components/EditIcon'
import { TrashIcon } from '../../components/TrashIcon'
import { UsersIcon } from '../../components/UsersIcon'

// Components
import BadgeContentEditor from './BadgeContentEditor'
import BadgeElementSettings from './BadgeElementSettings'
import { BadgePanel } from '../../components/BadgePanel'
import { BadgePreview } from '../../components/BadgePreview'
import { Button } from '../../components/Button'
import { EventHeader } from '../../components/EventHeader'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { getAttendees } from '../../services/attendees.service'
import {
  addBadge,
  deleteBadge,
  getBadges,
  getBadgeImage,
  updateBadge,
} from '../../services/badges.service'
import {
  getGlobalCustomFont,
  getGlobalCustomFonts,
  getGlobalStockSizes,
  getGoogleFonts,
} from '../../services/settings.service'

// Utils
import { toast } from '../../utils/helpers'
import { PIXEL_PER_INCH } from '../../utils/constants'
import { IS_UUID, findElementLocation, getGlobalFontsFromBadge } from './helpers'
import useFonts from '../../hooks/Fonts'

const CREATE_NEW_BADGE_OPTION = { id: -1, label: 'Create New Badge', alwaysDisplay: true }

const EMPTY_CONFIG = {
  1: {
    attrs: {
      width: 0,
      height: 0,
      config: { id: 'panel:1' },
    },
    className: 'Stage',
    children: [
      {
        attrs: { id: 'base' },
        className: 'Layer',
        children: [
          {
            attrs: {
              width: 0,
              height: 0,
              fill: 'white',
              id: 'base',
            },
            className: 'Rect',
          },
        ],
      },
    ],
  },
}

/**
 *
 * EventBadgeBuilder
 *
 */
const EventBadgeBuilder = observer(() => {
  // Context
  const navigate = useNavigate()
  const { event, organizationId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [error, setError] = useState(null)
  const [loadingBadgeSettings, setLoadingBadgeSettings] = useState(false)
  const [showBadgeSettingsModal, setShowBadgeSettingsModal] = useState(
    !event?.badgeConfiguration?.configObject,
  )
  const [showDeleteBadgeModal, setShowDeleteBadgeModal] = useState(false)
  const [badgeOptions, setBadgeOptions] = useState({ badges: [], fonts: [], stockSizes: [] })
  const [savingBadge, setSavingBadge] = useState(false)
  const [badgeBackground, setBadgeBackground] = useState(null)
  const [badgeImages, setBadgeImages] = useState({})

  // Preview Badge
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewError, setPreviewError] = useState(null)
  const [loadingAttendees, setLoadingAttendees] = useState(false)
  const [attendees, setAttendees] = useState(null)
  const [selectedAttendee, setSelectedAttendee] = useState(null)

  // Badge Designing
  const [history, setHistory] = useState([])
  const [customFonts, setCustomFonts] = useState([])
  const [customFontUrls, setCustomFontUrls] = useState([])
  const [googleFonts, setGoogleFonts] = useState([])
  const [config, setConfig] = useState(event?.badgeConfiguration?.configObject)
  const [selectedPanel, setSelectedPanel] = useState(1)
  const [defaultFont, setDefaultFont] = useState(
    event?.badgeConfiguration?.defaultFontGoogle || event?.badgeConfiguration?.defaultFontCustom,
  )
  const [activeElement, setActiveElement] = useState(null)
  const [tempGroupSettings, setTempGroupSettings] = useState(null)
  const [showGrid, setShowGrid] = useState(true)

  const handleSuccesses = (message) => toast(message, 'success')

  useFonts(customFontUrls)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      badge: CREATE_NEW_BADGE_OPTION,
      stockSize: null,
      numPanels: { id: 1, label: '1' },
      defaultFont: '',
    },
  })

  const {
    control: previewControl,
    handleSubmit: handlePreviewSubmit,
    formState: { errors: previewErrors },
    reset: previewReset,
  } = useForm({
    defaultValues: {
      attendees: null,
    },
  })

  useEffect(() => {
    // Set the badge configuration and fonts after they're set in the modal
    if (
      googleFonts.length === 0 &&
      customFonts.length === 0 &&
      (event.badgeConfiguration?.defaultFontGoogle ||
        event.badgeConfiguration?.defaultFontCustom) &&
      event.badgeConfiguration?.configObject
    ) {
      updateConfigAndHistory(JSON.parse(JSON.stringify(event.badgeConfiguration.configObject)))
      setDefaultFont(
        event.badgeConfiguration.defaultFontGoogle || event.badgeConfiguration.defaultFontCustom,
      )

      const [badgeGoogleFonts, badgeCustomFonts] = getGlobalFontsFromBadge(
        event.badgeConfiguration.defaultFontGoogle || event.badgeConfiguration.defaultFontCustom,
        event.badgeConfiguration.configObject,
      )

      setGoogleFonts(badgeGoogleFonts)
      setCustomFonts(badgeCustomFonts)
    }
  }, [event.badgeConfiguration])

  useEffect(() => {
    // Get all updated custom font data
    if (customFonts.length > 0) {
      // Check if there are any new fonts to load
      const fontsToLoad = _.filter(
        customFonts,
        (f) => _.find(customFontUrls, (url) => url.id === f) === undefined,
      )

      const fonts = []
      Promise.all(_.map(fontsToLoad, (f) => getGlobalCustomFont(f, setError, () => {}))).then(
        (data) => {
          _.forEach(data, (d) => {
            fonts.push(d)
          })

          setCustomFontUrls([...customFontUrls, ...fonts])
        },
      )
    }
  }, [customFonts])

  const getGlobalSettings = async () => {
    setLoadingBadgeSettings(true)
    const options = {}

    // Pull badge data (event and config)
    const data = await getBadges(setError, () => {})
    if (data) {
      const existingOptions = _.map(data.results, (b) => ({
        label: b.event.name,
        ...b,
      }))
      options.badges = [CREATE_NEW_BADGE_OPTION, ...existingOptions]
    }

    // Pull Google Fonts
    const fonts = await getGoogleFonts(setError, () => {})
    if (fonts) {
      const fontOptions = _.map(
        _.filter(
          fonts.items,
          (i) => i.variants.includes('regular') && i.variants.includes('italic'),
        ),
        (f) => ({
          id: f.family,
          label: f.family,
          ...f,
        }),
      )

      options.fonts = fontOptions
    }
    // Set default empty array
    else {
      options.fonts = []
    }

    // Pull Custom Fonts
    const globalFonts = await getGlobalCustomFonts(
      () => {},
      () => {},
    )

    if (globalFonts) {
      options.fonts = [
        ..._.map(globalFonts, (f) => ({ id: f.id, label: f.name, url: f.signedUrl })),
        ...options.fonts,
      ]
    }

    // Pull Stock sizes
    const stockSizes = await getGlobalStockSizes(setError, () => {})
    if (stockSizes) {
      options.stockSizes = _.map(stockSizes, (s) => ({
        ...s,
        label: s.name,
      }))
    }

    // Set the badge options
    setLoadingBadgeSettings(false)
    setBadgeOptions(options)
  }

  useEffect(() => {
    // If the badge config has not been set, get global settings for
    // panel sizes and fonts
    if (!event.badgeConfiguration) {
      getGlobalSettings()
    } else {
      const getStockSizes = async () => {
        const options = {}

        // Pull Stock sizes
        const stockSizes = await getGlobalStockSizes(setError, () => {})
        if (stockSizes) {
          options.stockSizes = _.map(stockSizes, (s) => ({
            ...s,
            label: s.name,
          }))
        }

        // Set the badge options
        setBadgeOptions(options)
      }

      getStockSizes()
    }
  }, [])

  const saveBadgeUpdates = async (badgeConfiguration) => {
    if (badgeConfiguration) {
      const response = await updateBadge(event.id, badgeConfiguration, setError, () => {})

      if (response) {
        setEvent({ ...event, badgeConfiguration: response })
      }
    }
  }

  /**
   * Debounce saving badge changes
   */
  const saveChanges = useCallback(_.debounce(saveBadgeUpdates, 500), [])

  useEffect(() => {
    if (config) {
      // Save the changes to the badge configuration
      saveChanges({
        id: event.badgeConfiguration.id,
        configObject: config,
        numPanels: _.keys(config).length,
      })

      // Set `badgeBackground` if there is a matching element in one of the panels
      let backgroundImage = null
      _.forEach(config, (panel) => {
        _.forEach(panel.children, (layer) => {
          _.forEach(layer.children, (element) => {
            if (element.className === 'Image' && element.attrs.isBackground) {
              backgroundImage = element
              setBadgeBackground(backgroundImage)
            }
          })
        })
      })

      if (!backgroundImage && badgeBackground) {
        setBadgeBackground(null)
      }

      // Pull out all images from badge
      const imagesOnBadge = {}
      _.forEach(config, (panel) => {
        _.forEach(panel.children, (layer) => {
          _.forEach(layer.children, (element) => {
            if (element.attrs.id === 'base') return

            if (badgeImages[element.attrs.id]) {
              imagesOnBadge[element.attrs.id] = badgeImages[element.attrs.id]
            } else if (element.className === 'Image' && !element.attrs.qrImage) {
              imagesOnBadge[element.attrs.id] = {
                url: element.attrs.badgeImagePath,
                base64: null,
              }
            }
          })
        })
      })

      // For tracked images where we don't have the base64 string, get it
      const images = _.filter(_.map(imagesOnBadge, (i, k) => !i.base64 && k))

      if (images.length > 0) {
        _.forEach(images, async (id) => {
          const badgeImageData = await getBadgeImage(
            imagesOnBadge[id].url,
            () => {},
            () => {},
          )

          imagesOnBadge[id] = badgeImageData
        })
      }

      // Track badge images
      setBadgeImages(imagesOnBadge)
    }
  }, [config])

  /**
   * Handles updating the badge config in state and tracking the history.
   * @param {object} updatedConfig
   */
  const updateConfigAndHistory = (updatedConfig) => {
    const updatedHistory = JSON.parse(JSON.stringify(history))
    updatedHistory.push(JSON.parse(JSON.stringify(updatedConfig)))
    console.log('(Badge Builder) History updated with', JSON.stringify(updatedConfig))
    // Track history
    setHistory(updatedHistory)
    setConfig(JSON.parse(JSON.stringify(updatedConfig)))
  }

  /**
   * Handles undoing changes to the badge.
   * - This function is debounced to prevent multiple calls in quick succession.
   * - Ensures that we keep track of the active element when undoing changes.
   * @param {array} newHistory
   * @param {object} panel
   * @param {object} element
   */
  const undoBadgeChanges = (newHistory, panel, element) => {
    console.log('(Badge Builder) Undoing changes')
    const updatedHistory = JSON.parse(JSON.stringify(newHistory))
    updatedHistory.pop()
    setHistory(updatedHistory)

    const updatedConfig = JSON.parse(JSON.stringify(updatedHistory[updatedHistory.length - 1]))
    setConfig(updatedConfig)

    // Pull active element from updatedConfig and update it
    if (!element) return
    const location = findElementLocation(updatedConfig, element.attrs.id)
    let updatedElement = null

    if (location.groupElementIndex !== null) {
      updatedElement =
        updatedConfig[panel].children[location.layerIndex].children[location.elementIndex]
          .children[location.groupElementIndex]
    } else {
      updatedElement =
        updatedConfig[panel].children[location.layerIndex].children[location.elementIndex]
    }

    setActiveElement(updatedElement)
  }

  const debounceUndoBadgeChanges = useCallback(_.debounce(undoBadgeChanges, 500), [])

  /**
   * Handles submitting the badge settings form.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const badgeConfig = data.badge.id === -1 ? EMPTY_CONFIG : data.badge.configObject

    const payload = {
      event: event.id,
      stockSize: null,
      numPanels: null,
      defaultFontGoogle: null,
      defaultFontCustom: null,
    }

    // If the badge is new, update the size and fonts according to selections
    if (data.badge.id === -1) {
      // Round down the width and height to the nearest whole number
      // to prevent issues with the badge editor
      const width = Math.floor(parseFloat(data.stockSize.widthInInches, 10) * PIXEL_PER_INCH)
      const height = Math.floor(parseFloat(data.stockSize.heightInInches, 10) * PIXEL_PER_INCH)

      // Base Panel Size
      badgeConfig[1].attrs.width = width
      badgeConfig[1].attrs.height = height

      // Base Panel Background Size
      badgeConfig[1].children[0].children[0].attrs.width = width
      badgeConfig[1].children[0].children[0].attrs.height = height

      // If there are more than 1 panels, duplicate the base panel for each additional
      if (data.numPanels.id > 1) {
        for (let i = 2; i <= data.numPanels.id; i += 1) {
          badgeConfig[i] = _.cloneDeep(badgeConfig[1])
          badgeConfig[i].attrs.config.id = `panel:${i}`
        }
      }

      payload.stockSize = data.stockSize.id
      payload.numPanels = data.numPanels.id
      payload.defaultFontGoogle = data.defaultFont.family || null
      payload.defaultFontCustom = !data.defaultFont.family ? data.defaultFont.id : null
    } else {
      payload.stockSize = data.badge.stockSize
      payload.numPanels = data.badge.numPanels
      payload.defaultFontGoogle = data.badge.defaultFontGoogle
      payload.defaultFontCustom = data.badge.defaultFontCustom
    }

    payload.configObject = badgeConfig

    const badge = await addBadge(event.id, payload, setError, setSavingBadge, handleSuccesses)
    if (badge) {
      updateConfigAndHistory(badge.configObject)
      setDefaultFont(badge.defaultFontGoogle || badge.defaultFontCustom)
      setShowBadgeSettingsModal(false)
      setEvent({ ...event, badgeConfiguration: badge })
    }
  }

  /**
   * Handles updates to elements within a panel.
   * - Does NOT handle moving elements between layers.
   * @param {object} element
   */
  const handleElementUpdates = (element, layerIndex = null) => {
    const location = findElementLocation(config, element.attrs?.id)
    const newConfig = { ...config }
    const newPanel = { ...newConfig[selectedPanel] }

    // Add a new layer when there is no `layerIndex` specified
    if (element.className === 'Layer' && location.layerIndex === null) {
      const index = newPanel.children.length
      const newLayer = {
        attrs: {
          id: `${uuidv4()}`,
          visible: true,
          visibility: 'always',
          editable: true,
          index,
          name: `Layer ${index}`,
        },
        className: 'Layer',
        children: [],
      }

      newPanel.children.push(newLayer)
      newConfig[selectedPanel] = newPanel
      updateConfigAndHistory(newConfig)

      return
    }

    // Update an existing layer
    if (element.className === 'Layer') {
      const newLayer = { ...newPanel.children[location.layerIndex] }
      newLayer.attrs = { ...newLayer.attrs, ...element.attrs }
      newPanel.children[location.layerIndex] = newLayer

      newConfig[selectedPanel] = newPanel
      updateConfigAndHistory(newConfig)

      return
    }

    /* All other element types */

    const newLayer = {
      ...newPanel.children[location.layerIndex !== null ? location.layerIndex : layerIndex],
    }

    if (location.elementIndex !== null && location.elementIndex !== -1) {
      newLayer.children[location.elementIndex] = element
      newPanel.children[location.layerIndex] = newLayer
    }
    // New Element (not a group), configure unique `id`
    else {
      const newElement = { ...element.element }
      let fontFamily = null
      let fontId = null

      /**
       * For text elements, we need to properly configure the default font.
       * Custom fonts are stored as UUIDs, so we need to look up the matching font
       * object in order to get the name to pass as the `fontFamily`.
       *
       * Google fonts are stored as the font family automatically.
       */
      if (newElement.className === 'Text') {
        const isCustomFont = IS_UUID.test(defaultFont)

        if (isCustomFont) {
          fontFamily = _.find(customFontUrls, (f) => f.id === defaultFont)?.name
          fontId = defaultFont
        } else {
          fontFamily = defaultFont
        }
      }

      newElement.attrs = {
        ...newElement.attrs,
        id: `${uuidv4()}`,
        visible: true,
        visibility: 'always',
        editable: element.editable,
        qrPrint: newElement.attrs.qrImage ? 'no-print' : null,
        align: newElement.className === 'Text' ? 'left' : null,
        fill: newElement.className === 'Text' || newElement.className === 'Rect' ? '#000' : null,
        fontFamily,
        fontId,
        fontSize: newElement.className === 'Text' ? '16' : null,
        fontStyle: newElement.className === 'Text' ? 'normal' : null,
        lockRatio: newElement.className === 'Image',
        isBackground: newElement.className === 'Image' ? false : null,
        stroke:
          newElement.className === 'Image' || newElement.className === 'Rect' ? '#000' : null,
        strokeWidth:
          newElement.className === 'Image' || newElement.className === 'Rect' ? 0 : null,
        textDecoration: newElement.className === 'Text' ? '' : null,
        uppercase: newElement.className === 'Text' ? false : null,
        wrap: newElement.className === 'Text' ? 'none' : null,
        x: 0,
        y: 0,
      }
      newLayer.children.push(newElement)
      newPanel.children[layerIndex] = newLayer
    }

    newConfig[selectedPanel] = newPanel
    updateConfigAndHistory(newConfig)
  }

  /**
   * Handles resizing the badge.
   * - All elements that are outside of the new panel size are moved inside the panel.
   * @param {object} newSize
   */
  const handleConfigResize = (newSize) => {
    const newWidth = Math.floor(parseFloat(newSize.widthInInches, 10) * PIXEL_PER_INCH)
    const newHeight = Math.floor(parseFloat(newSize.heightInInches, 10) * PIXEL_PER_INCH)

    // Update the panel sizes and layer sizes
    const newConfig = JSON.parse(JSON.stringify(config))

    _.forEach(newConfig, (panel) => {
      panel.attrs.width = newWidth
      panel.attrs.height = newHeight

      _.forEach(panel.children, (layer) => {
        layer.attrs.width = newWidth
        layer.attrs.height = newHeight

        _.forEach(layer.children, (element) => {
          if (element.attrs.id === 'base') {
            element.attrs.width = newWidth
            element.attrs.height = newHeight
          }
        })
      })
    })

    // Find all elements where the bottom left corner is outside of the new panel size and
    // move them inside the panel
    _.forEach(newConfig, (panel) => {
      _.forEach(panel.children, (layer) => {
        _.forEach(layer.children, (element) => {
          if (element.attrs.id === 'base') return
          const { x } = element.attrs
          const y = element.attrs.y + element.attrs.height

          if (x > newWidth) {
            element.attrs.x = newWidth - element.attrs.width
          }

          if (y > newHeight) {
            element.attrs.y = newHeight - element.attrs.height
          }
        })
      })
    })

    setConfig(newConfig)

    // Update the badge configuration
    saveBadgeUpdates({
      id: event.badgeConfiguration.id,
      stockSize: newSize.id,
    })
  }

  /**
   * Handles verifying specific changes for a text element.
   * - Some changes are handled by the `BadgeElementSettings` component, so we
   *  want to check that the changes being made are pieces that would be made
   *  outside of the settings.
   * @param {object} oldAttrs
   * @param {object} newAttrs
   *
   */
  const verifyTextElementChanges = (oldAttrs, newAttrs) => {
    const keysToCheck = ['text', 'width', 'height', 'rotation', 'x', 'y']
    const changes = _.pickBy(newAttrs, (v, k) => {
      if (keysToCheck.includes(k)) {
        return oldAttrs[k] !== v
      }

      return false
    })

    return Object.keys(changes).length > 0
  }

  /**
   * Options are based on the number of panels, with the first two being 'Front' and 'Back'.
   * The rest are labeled 'Extra 1' and 'Extra 2'.
   * - If there are less than 4 panels, an option to add a new panel is included.
   * @returns {array} options
   */
  const configurePanelOptions = useMemo(() => {
    if (event.badgeConfiguration) {
      const options = _.range(1, event.badgeConfiguration.numPanels + 1).map((i) => {
        if (i === 1) return { id: i, label: 'Front' }
        if (i === 2) return { id: i, label: 'Back' }
        return { id: i, label: `Extra ${i - 2}` }
      })

      // If `enableMirrorContent`, change the label of the Front option and remove the Back option
      if (event.badgeConfiguration.enableMirrorContent) {
        options[0].label = 'Front & Back'
        options.splice(1, 1)
      }

      if (event.badgeConfiguration.numPanels < 4) {
        options.push({ id: -1, label: '+ Add Panel' })
      }

      return options
    }

    return []
  }, [event.badgeConfiguration])

  /**
   * Options are based on the number of layers in the selected panel.
   */
  const configureLayerOptions = useMemo(() => {
    const options = []

    if (event.badgeConfiguration && event.badgeConfiguration.configObject && selectedPanel) {
      _.forEach(event.badgeConfiguration.configObject[selectedPanel]?.children, (layer, index) => {
        if (layer.attrs.id === 'base') options.push({ id: 0, label: 'Base' })
        else options.push({ id: index, label: layer.attrs.name || `Layer ${index}` })
      })
    }

    return options
  }, [event.badgeConfiguration, selectedPanel])

  /**
   * Renders the badge settings content based on the state of the badge settings.
   */
  const renderBadgeSettingContent = () => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">Error Loading Settings</span>
          <span className="text-sm text-red-500">{error}</span>
        </div>
      )
    }

    if (loadingBadgeSettings) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold">Loading Settings...</span>

          <span className="flex items-center pr-3">
            <div className="h-5 w-5">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-5 w-5 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    return (
      <div className="flex flex-col space-y-4 sm:mt-5">
        <span className="mx-2 text-sm">
          Select from the dropdown below to create a new badge or duplicate an existing badge as a
          starting point.
        </span>

        <Controller
          name="badge"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="badge"
              disabled={savingBadge}
              error={errors.badge && 'This field is required'}
              fullWidth
              id="badge"
              name="badge"
              nunito
              onChange={onChange}
              options={badgeOptions.badges}
              label="Base Badge"
              placeholder="Select a Base Badge"
              search
              style={{ flex: true, width: '100%' }}
              value={value}
            />
          )}
          rules={{ required: true }}
        />

        {watch('badge').id === -1 && (
          <div className="space-y-4">
            <Controller
              name="stockSize"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="organization"
                  disabled={savingBadge}
                  error={errors.stockSize && 'This field is required'}
                  fullWidth
                  id="stockSize"
                  name="stockSize"
                  nunito
                  onChange={onChange}
                  options={badgeOptions.stockSizes}
                  label="Panel Size"
                  placeholder="Select Panel Size"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />

            <Controller
              name="numPanels"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="organization"
                  disabled={savingBadge}
                  error={errors.numPanels && 'This field is required'}
                  fullWidth
                  id="numPanels"
                  name="numPanels"
                  nunito
                  onChange={onChange}
                  options={[
                    { id: 1, label: '1' },
                    { id: 2, label: '2' },
                    { id: 3, label: '3' },
                    { id: 4, label: '4' },
                  ]}
                  label="Number of Panels"
                  placeholder="Select Number of Panels"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />

            <Controller
              name="defaultFont"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  disabled={savingBadge}
                  error={errors.defaultFont && 'This field is required'}
                  fullWidth
                  id="defaultFont"
                  name="defaultFont"
                  nunito
                  onChange={onChange}
                  openAbove
                  options={badgeOptions.fonts}
                  label="Badge Default Font"
                  placeholder="Select a Font"
                  search
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * Renders the preview content based on the state of the preview settings.
   */
  const renderPreviewContent = () => {
    if (previewError) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">Error Loading Attendees</span>
          <span className="text-sm text-red-500">{error}</span>
        </div>
      )
    }

    if (loadingAttendees) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold">Loading Attendees...</span>

          <span className="flex items-center pr-3">
            <div className="h-5 w-5">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-5 w-5 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    return (
      <div className="flex flex-col">
        <span className="mx-2 text-sm">
          Search and select one or more Attendees from the list below to preview badges.
        </span>

        <Controller
          name="attendees"
          control={previewControl}
          render={({ field: { onChange, value } }) => (
            <Select
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="attendees"
              error={previewErrors.attendees && 'This field is required'}
              fullWidth
              id="attendees"
              name="attendees"
              nunito
              onChange={onChange}
              options={attendees}
              label="Attendees"
              placeholder="Select Attendees"
              search
              style={{ flex: true, width: '100%' }}
              value={value || null}
            />
          )}
          rules={{ required: true }}
        />
      </div>
    )
  }

  return (
    <div className="h-screen w-full">
      <StateContainer>
        <div className="relative flex h-screen w-full flex-col space-y-5 overflow-y-auto py-3">
          <div className="flex flex-col items-start justify-between space-y-2 px-3 sm:flex-row sm:space-y-0 md:items-center">
            <EventHeader event={event} />

            <div className="flex flex-row items-center space-x-1 sm:items-end sm:space-y-1">
              <button
                className="flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-purple p-[6px] hover:bg-purple-400 disabled:cursor-not-allowed disabled:opacity-50"
                disabled={history.length < 2}
                onClick={() => debounceUndoBadgeChanges(history, selectedPanel, activeElement)}
                title="Undo Recent Panel Change"
                type="button"
              >
                <FaUndo color="white" size={22} />
              </button>

              <Button
                label="Preview"
                onClick={() => {
                  setShowPreviewModal(true)
                  getAttendees(
                    `/events/${event.id}/attendees/?limit=999999`,
                    setPreviewError,
                    setLoadingAttendees,
                    (d) =>
                      setAttendees(
                        _.map(d.results, (a) => ({ ...a, label: `${a.firstName} ${a.lastName}` })),
                      ),
                  )
                }}
              />

              <Button label="Delete" onClick={() => setShowDeleteBadgeModal(true)} />
            </div>
          </div>

          <div className="flex h-full flex-col items-center justify-center px-16 py-1 lg:hidden">
            <ExclamationCircleIcon className="h-12 w-12" />
            <span className="text-center text-2xl font-bold">
              Badge Builder is not optimized for smaller screen sizes.
            </span>
          </div>

          <div className="hidden h-[calc(100vh-98px)] w-full flex-col items-center lg:flex">
            <div className="flex h-full w-full gap-9">
              <BadgeContentEditor
                addElementToPanel={handleElementUpdates}
                handleChangeStockSize={handleConfigResize}
                handlePanelUpdates={(type) => {
                  if (type === 'add') {
                    const newConfig = { ...config }

                    // Use the first panel as a template for the new panel
                    const newPanel = EMPTY_CONFIG[1]

                    // Update the panel id and width/height
                    newPanel.attrs.config.id = `panel:${_.keys(config).length + 1}`
                    newPanel.attrs.width = config[1].attrs.width
                    newPanel.attrs.height = config[1].attrs.height
                    newPanel.children[0].children[0].attrs.width =
                      config[1].children[0].children[0].attrs.width
                    newPanel.children[0].children[0].attrs.height =
                      config[1].children[0].children[0].attrs.height

                    newConfig[_.keys(config).length + 1] = newPanel

                    updateConfigAndHistory(newConfig)
                    handleSuccesses('Panel added successfully.')
                  }
                  // If the type is 'mirror', toggle the mirror content
                  else if (type === 'mirror') {
                    setSelectedPanel(1)
                    saveChanges({
                      ...event.badgeConfiguration,
                      enableMirrorContent: !event.badgeConfiguration.enableMirrorContent,
                    })
                  }
                }}
                enableMirrorContent={event.badgeConfiguration?.enableMirrorContent}
                layers={config && _.map(config[selectedPanel]?.children, (l) => l)}
                mergeFields={event.availableCustomFields}
                moveLayer={(source, destination) => {
                  console.log('(Badge Builder) Moving layer', source, destination)
                  const newConfig = _.cloneDeep(config)
                  const newPanel = { ...newConfig[selectedPanel] }

                  const [removed] = newPanel.children.splice(source, 1)
                  newPanel.children.splice(destination, 0, removed)

                  newConfig[selectedPanel] = newPanel
                  updateConfigAndHistory(newConfig)
                }}
                numOfPanels={event.badgeConfiguration?.numPanels}
                panelOptions={configurePanelOptions}
                showGrid={showGrid}
                selectedPanel={selectedPanel}
                setSelectedPanel={(p) => {
                  setSelectedPanel(p)

                  // Clear active element
                  setActiveElement(null)

                  // Reset history
                  setHistory([])
                }}
                setShowGrid={setShowGrid}
                stockOptions={badgeOptions.stockSizes}
                stockSize={event.badgeConfiguration?.stockSize}
                toggleLayerSettings={setActiveElement}
                updateLayerVisibility={handleElementUpdates}
              />

              <div className="flex w-1/2 justify-center">
                {config && (
                  <BadgePanel
                    badgeBackground={badgeBackground}
                    badgeImages={badgeImages}
                    defaultFont={defaultFont}
                    savedPanel={config[selectedPanel]}
                    setActiveElement={setActiveElement}
                    setBadgeBackground={setBadgeBackground}
                    setTemporaryGroup={setTempGroupSettings}
                    showGrid={showGrid}
                    tempGroup={tempGroupSettings}
                    updatePanel={(id, update) => {
                      const location = findElementLocation(config, id)
                      let itemToUpdate = { ...activeElement }

                      // Sometimes group can be weird and not update correctly, or the group
                      // will become the active element too quickly based on timing. So, we need
                      // to verify that the updates being passed through are for the correct element.
                      // If not, we need to find the correct element to update.
                      if (activeElement === null || activeElement.attrs.id !== id) {
                        if (location.groupElementIndex !== null) {
                          itemToUpdate = _.cloneDeep(
                            config[selectedPanel].children[location.layerIndex].children[
                              location.elementIndex
                            ].children[location.groupElementIndex],
                          )
                        } else {
                          itemToUpdate = _.cloneDeep(
                            config[selectedPanel].children[location.layerIndex].children[
                              location.elementIndex
                            ],
                          )
                        }
                      }

                      if (itemToUpdate.attrs.inGroup) {
                        const layer = config[selectedPanel].children[location.layerIndex]
                        const group = layer.children[location.elementIndex]

                        // Update element within group
                        const updatedGroup = _.cloneDeep(group)
                        const updated = {
                          className: itemToUpdate.className,
                          attrs: {
                            ...itemToUpdate.attrs,
                            ...update,
                          },
                        }
                        updatedGroup.children[location.groupElementIndex] = updated

                        handleElementUpdates(updatedGroup)
                        setActiveElement(updated)
                      }
                      // Otherwise, update the element like normal
                      else {
                        const updatedElement = {
                          className: itemToUpdate.className,
                          attrs: { ...itemToUpdate.attrs, ...update },
                        }

                        if (itemToUpdate.className === 'Group') {
                          updatedElement.children = itemToUpdate.children
                        }

                        // If the element is a text element, check to make sure that there were
                        // changes to the text before updating the active element. This will prevent
                        // the settings from being toggled back on when clicking outside of a text
                        // element.
                        if (
                          updatedElement.className !== 'Text' ||
                          verifyTextElementChanges(itemToUpdate.attrs, updatedElement.attrs)
                        ) {
                          handleElementUpdates(updatedElement)
                          setActiveElement(updatedElement)
                        }
                      }
                    }}
                  />
                )}
              </div>

              <BadgeElementSettings
                activeElement={activeElement}
                badgeHeight={config && config[selectedPanel].attrs.height}
                badgeWidth={config && config[selectedPanel].attrs.width}
                categories={
                  event.attendeeCategories &&
                  _.map(_.keys(event.attendeeCategories), (c) => ({
                    id: event.attendeeCategories[c].category_id,
                    label: _.capitalize(c),
                  }))
                }
                config={config}
                copyElement={(type, index) => {
                  console.log('(Badge Builder) Copying element', type, index)
                  const newConfig = _.cloneDeep(config)

                  // When copying an element to another panel, we want to find it in
                  // the current panel, and add it to the base layer of the new panel.
                  if (type === 'panel') {
                    const newPanel = { ...newConfig[index] }
                    const newLayer = { ...newPanel.children[0] }

                    // Get the element from its current position and copy it
                    const location = findElementLocation(config, activeElement.attrs.id)
                    const element = _.cloneDeep(
                      config[selectedPanel].children[location.layerIndex].children[
                        location.elementIndex
                      ],
                    )

                    // Update element's id
                    element.attrs.id = `${uuidv4()}`

                    // If the element has children, update those ids
                    if (element.children) {
                      _.forEach(element.children, (c) => {
                        c.attrs.id = `${uuidv4()}`
                      })
                    }

                    newLayer.children.push(element)
                    newPanel.children[0] = newLayer
                    newConfig[index] = newPanel

                    handleSuccesses('Element copied successfully.')
                  } else if (type === 'layer') {
                    // When copying an element to another layer, we want to find it in
                    // the current layer, and add it to the new layer.
                    const newLayer = { ...newConfig[selectedPanel].children[index] }

                    // Get the element from its current position and copy it
                    const location = findElementLocation(config, activeElement.attrs.id)
                    const element = _.cloneDeep(
                      config[selectedPanel].children[location.layerIndex].children[
                        location.elementIndex
                      ],
                    )

                    // If the element has children, update those ids
                    if (element.children) {
                      _.forEach(element.children, (c) => {
                        c.attrs.id = `${uuidv4()}`
                      })
                    }

                    // Update element's id
                    element.attrs.id = `${uuidv4()}`
                    newLayer.children.push(element)
                    newConfig[selectedPanel].children[index] = newLayer

                    handleSuccesses('Element copied successfully.')
                  }

                  updateConfigAndHistory(newConfig)
                }}
                createGroup={() => {
                  console.log(
                    '(Badge Builder) Creating group from elements',
                    JSON.stringify(tempGroupSettings),
                  )
                  // Pull the layer index off the first item selected so we know what
                  // layer to add the group to
                  const location = findElementLocation(config, tempGroupSettings[0].attrs.id)
                  const layer = location.layerIndex

                  const newConfig = { ...config }
                  const newPanel = { ...newConfig[selectedPanel] }
                  const newLayer = { ...newPanel.children[layer] }

                  // Determing the height and width of the group by the x, y, width, and height of the children
                  const minX = _.minBy(tempGroupSettings, (c) => c.attrs.x).attrs.x
                  const minY = _.minBy(tempGroupSettings, (c) => c.attrs.y).attrs.y
                  const maxX =
                    _.maxBy(tempGroupSettings, (c) => c.attrs.x + c.attrs.width).attrs.x +
                    _.maxBy(tempGroupSettings, (c) => c.attrs.x + c.attrs.width).attrs.width
                  const maxY =
                    _.maxBy(tempGroupSettings, (c) => c.attrs.y + c.attrs.height).attrs.y +
                    _.maxBy(tempGroupSettings, (c) => c.attrs.y + c.attrs.height).attrs.height
                  const width = maxX - minX
                  const height = maxY - minY

                  // Adjust child positions to be relative to the group
                  _.forEach(tempGroupSettings, (c) => {
                    c.attrs.x -= minX
                    c.attrs.y -= minY
                    c.attrs.inGroup = true
                  })

                  // Create a new group
                  const newGroup = {
                    attrs: {
                      id: `${uuidv4()}`,
                      visibility: 'always',
                      editable: true,
                      width,
                      height,
                      x: minX,
                      y: minY,
                      align: null,
                      stroke: '#000',
                      strokeWidth: 0,
                      fill: 'transparent',
                      className: 'Group',
                    },
                    className: 'Group',
                    children: tempGroupSettings,
                  }

                  // Ensure that the order is preserved when group
                  // We want to sort the group elements by their index in the layer
                  newGroup.children = _.sortBy(newGroup.children, (c) => {
                    const childLocation = findElementLocation(config, c.attrs.id)
                    return childLocation.elementIndex
                  })

                  // Remove the elements from the layer
                  _.forEach(tempGroupSettings, (el) => {
                    const index = _.findIndex(newLayer.children, (c) => c.attrs.id === el.attrs.id)
                    newLayer.children.splice(index, 1)
                  })

                  // Add the group to the layer
                  newLayer.children.push(newGroup)
                  newPanel.children[layer] = newLayer
                  newConfig[selectedPanel] = newPanel

                  updateConfigAndHistory(newConfig)
                  setTempGroupSettings(null)
                  setActiveElement(null)
                }}
                currentPanelIndex={selectedPanel}
                deleteElement={() => {
                  console.log('(Badge Builder) Deleting element', activeElement.attrs.id)
                  const location = findElementLocation(config, activeElement.attrs.id)
                  const newConfig = { ...config }
                  const newPanel = { ...newConfig[selectedPanel] }

                  if (activeElement.className === 'Layer') {
                    // Remove the layer from the panel
                    newPanel.children.splice(location.layerIndex, 1)
                  } else if (activeElement.className === 'Group') {
                    const newLayer = {
                      ...newPanel.children[location.layerIndex],
                    }

                    // Remove the group from the layer
                    const [group] = newLayer.children.splice(location.elementIndex, 1)

                    // Update the positions of each element from the group
                    _.forEach(group.children, (el) => {
                      el.attrs.x += group.attrs.x
                      el.attrs.y += group.attrs.y
                      el.attrs.inGroup = false
                    })

                    // Add the elements back to the layer
                    newLayer.children = [...newLayer.children, ...group.children]
                    newPanel.children[location.layerIndex] = newLayer
                  } else {
                    const newLayer = {
                      ...newPanel.children[location.layerIndex],
                    }

                    if (activeElement.attrs.inGroup) {
                      // Remove the element from the group
                      const group = newLayer.children[location.elementIndex]
                      group.children.splice(location.groupElementIndex, 1)

                      // If the group is empty, remove it from the layer
                      if (group.children.length === 0) {
                        newLayer.children.splice(location.elementIndex, 1)
                      }

                      newPanel.children[location.layerIndex] = newLayer
                    } else {
                      // Remove the element from the layer
                      newLayer.children.splice(location.elementIndex, 1)
                      newPanel.children[location.layerIndex] = newLayer
                    }
                  }

                  newConfig[selectedPanel] = newPanel
                  updateConfigAndHistory(newConfig)
                  setActiveElement(null)
                }}
                handleSuccesses={handleSuccesses}
                layers={configureLayerOptions}
                mergeFields={event.availableCustomFields}
                moveElement={(type, index) => {
                  console.log('(Badge Builder) Moving element', type, index)
                  const newConfig = _.cloneDeep(config)

                  // When moving an element to another layer, we want to remove it from
                  // the current layer and add it to the new one.
                  if (type === 'layer') {
                    const newLayer = { ...newConfig[selectedPanel].children[index] }

                    // Remove the element from its current layer
                    const location = findElementLocation(config, activeElement.attrs.id)
                    const layerChildren =
                      newConfig[selectedPanel].children[location.layerIndex].children
                    const [element] = layerChildren.splice(location.elementIndex, 1)

                    // Add the element to the layer and update the config
                    if (element) newLayer.children.push(element)
                    newConfig[selectedPanel].children[index] = newLayer

                    handleSuccesses('Element moved successfully.')
                  } else if (type === 'forward') {
                    const location = findElementLocation(config, activeElement.attrs.id)

                    // If the element is in a group, we need to move it within the group
                    if (activeElement.attrs.inGroup) {
                      const layer = config[selectedPanel].children[location.layerIndex]
                      const group = layer.children[location.elementIndex]

                      const element = group.children.splice(location.groupElementIndex, 1)[0]
                      if (element)
                        group.children.splice(location.groupElementIndex + 1, 0, element)
                    } else {
                      const newLayer = {
                        ...newConfig[selectedPanel].children[location.layerIndex],
                      }
                      const element = newLayer.children.splice(location.elementIndex, 1)[0]

                      if (element) newLayer.children.splice(location.elementIndex + 1, 0, element)
                      newConfig[selectedPanel].children[location.layerIndex] = newLayer
                    }
                  } else if (type === 'backward') {
                    const location = findElementLocation(config, activeElement.attrs.id)

                    // If the element is in a group, we need to move it within the group
                    if (activeElement.attrs.inGroup) {
                      const layer = config[selectedPanel].children[location.layerIndex]
                      const group = layer.children[location.elementIndex]

                      const element = group.children.splice(location.groupElementIndex, 1)[0]
                      if (element)
                        group.children.splice(location.groupElementIndex - 1, 0, element)
                    } else {
                      const newLayer = {
                        ...newConfig[selectedPanel].children[location.layerIndex],
                      }

                      const element = newLayer.children.splice(location.elementIndex, 1)[0]

                      // Don't allow moving the element behind the base element
                      if (newLayer.children[location.elementIndex - 1].attrs.id === 'base') {
                        return
                      }

                      if (element) newLayer.children.splice(location.elementIndex - 1, 0, element)
                      newConfig[selectedPanel].children[location.layerIndex] = newLayer
                    }
                  } else if (type === 'front') {
                    const location = findElementLocation(config, activeElement.attrs.id)

                    // If the element is in a group, we need to move it within the group
                    if (activeElement.attrs.inGroup) {
                      const layer = config[selectedPanel].children[location.layerIndex]
                      const group = layer.children[location.elementIndex]

                      const element = group.children.splice(location.groupElementIndex, 1)[0]
                      if (element) group.children.push(element)
                    } else {
                      const newLayer = {
                        ...newConfig[selectedPanel].children[location.layerIndex],
                      }
                      const element = newLayer.children.splice(location.elementIndex, 1)[0]

                      if (element) newLayer.children.push(element)
                      newConfig[selectedPanel].children[location.layerIndex] = newLayer
                    }
                  } else if (type === 'back') {
                    const location = findElementLocation(config, activeElement.attrs.id)

                    // If the element is in a group, we need to move it within the group
                    if (activeElement.attrs.inGroup) {
                      const layer = config[selectedPanel].children[location.layerIndex]
                      const group = layer.children[location.elementIndex]

                      const element = group.children.splice(location.groupElementIndex, 1)[0]
                      if (element) group.children.unshift(element)
                    } else {
                      const newLayer = {
                        ...newConfig[selectedPanel].children[location.layerIndex],
                      }

                      const element = newLayer.children.splice(location.elementIndex, 1)[0]
                      const base = newLayer.children.splice(0, 1)[0]

                      // Add the element first, then the base background rect to make sure it's always in the back
                      if (element) newLayer.children.unshift(element)
                      newLayer.children.unshift(base)

                      newConfig[selectedPanel].children[location.layerIndex] = newLayer
                    }
                  }

                  updateConfigAndHistory(newConfig)
                }}
                panels={configurePanelOptions}
                selectedPanel={selectedPanel}
                sessions={event.sessions}
                setCustomFonts={(update) => setCustomFonts([...customFonts, update])}
                setGoogleFonts={(update) => setGoogleFonts([...googleFonts, update])}
                temporaryGroup={tempGroupSettings}
                updateElement={(update) => {
                  if (activeElement.className === 'Layer') {
                    const updatedLayer = {
                      className: 'Layer',
                      children: activeElement.children,
                      attrs: { ...activeElement.attrs, ...update },
                    }
                    handleElementUpdates(updatedLayer)
                    setActiveElement(updatedLayer)
                  } else if (activeElement.attrs.inGroup) {
                    const location = findElementLocation(config, activeElement.attrs.id)
                    const layer = config[selectedPanel].children[location.layerIndex]
                    const group = layer.children[location.elementIndex]

                    // Update element within group
                    const updatedGroup = _.cloneDeep(group)
                    const updated = {
                      className: activeElement.className,
                      attrs: {
                        ...activeElement.attrs,
                        ...update,
                      },
                    }
                    updatedGroup.children[location.groupElementIndex] = updated

                    handleElementUpdates(updatedGroup)
                    setActiveElement(updated)
                  }
                  // Otherwise, update the element like normal
                  else {
                    const updatedElement = {
                      className: activeElement.className,
                      attrs: { ...activeElement.attrs, ...update },
                    }

                    if (activeElement.className === 'Group') {
                      updatedElement.children = activeElement.children
                    }

                    handleElementUpdates(updatedElement)
                    setActiveElement(updatedElement)
                  }
                }}
              />
            </div>
          </div>
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Back to Check-In Settings',
            onClick: () => {
              navigate(`/organization/${organizationId}/event/${event.id}/check-in/settings`)
            },
          },
          {
            type: 'submit',
            label: 'Save & Continue',
            onClick: handleSubmit(onSubmit),
          },
        ]}
        disabled={loadingBadgeSettings}
        icon={<EditIcon className="h-5 fill-white stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col text-center sm:mt-5">
            {renderBadgeSettingContent()}
          </div>
        }
        loading={savingBadge}
        open={showBadgeSettingsModal}
        title="Start from Previous Badge?"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setShowDeleteBadgeModal(false),
          },
          {
            type: 'submit',
            label: 'Delete Badge',
            onClick: () => {
              if (event.badgeConfiguration?.id) {
                deleteBadge(
                  event.id,
                  event.badgeConfiguration.id,
                  () => setError('error deleting badge'),
                  () => {},
                  async (m) => {
                    handleSuccesses(m)
                    getGlobalSettings()

                    // Clear the badge configuration and reset the form
                    setEvent({ ...event, badgeConfiguration: null })
                    setConfig(null)
                    setHistory([])
                    reset()

                    setShowBadgeSettingsModal(true)
                    setShowDeleteBadgeModal(false)
                  },
                )
              }
            },
            background: 'bg-red',
          },
        ]}
        disabled={loadingBadgeSettings}
        icon={<TrashIcon className="h-6 fill-white" />}
        iconBackground="bg-red"
        content={
          <div className="mt-3 flex flex-col text-center text-sm sm:mt-5">
            Deleting this badge is irreversible. Are you sure you want to continue?
          </div>
        }
        loading={savingBadge}
        open={showDeleteBadgeModal}
        title="Delete Badge?"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setShowPreviewModal(false)
              setPreviewError(null)
              previewReset()
            },
          },
          {
            disabled: previewError,
            type: 'submit',
            label: 'Open Preview',
            onClick: handlePreviewSubmit((data) => {
              setSelectedAttendee(data.attendees)
              setShowPreviewModal(false)
              previewReset()
            }),
          },
        ]}
        disabled={loadingBadgeSettings}
        icon={<UsersIcon className="h-5 fill-white stroke-white sm:h-6" />}
        content={<div className="mt-3 text-center sm:mt-5">{renderPreviewContent()}</div>}
        open={showPreviewModal}
        title="Attendees to Preview"
      />

      {selectedAttendee && (
        <BadgePreview
          attendee={selectedAttendee}
          badgeImages={badgeImages}
          categories={event.attendeeCategories}
          config={event.badgeConfiguration}
          endPreview={() => setSelectedAttendee(null)}
        />
      )}

      {googleFonts && googleFonts.length > 0 && <GoogleFontLoader fonts={googleFonts} />}
    </div>
  )
})

export default EventBadgeBuilder
