import React, { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'

const Modal = ({
  actions,
  className,
  containerClassName,
  description,
  disabled,
  icon,
  iconBackground,
  content,
  loading,
  onClose,
  open,
  setOpen,
  title,
}) => {
  const renderActions = () => {
    if (_.isEmpty(actions)) return null

    const submitAction = _.find(actions, (a) => a.type === 'submit')
    const cancelAction = _.find(actions, (a) => a.type === 'cancel')
    const deleteAction = _.find(actions, (a) => a.type === 'delete')

    if (!submitAction && !deleteAction) {
      return (
        <div className="mt-5 sm:mt-6">
          <Button
            background="bg-white"
            disabled={disabled}
            fullWidth
            loading={loading}
            onClick={() => {
              if (cancelAction.onClick) cancelAction.onClick()
              if (setOpen) setOpen(false)
            }}
            outlined
            label={cancelAction.label}
          />
        </div>
      )
    }

    if (deleteAction) {
      return (
        <>
          <div className="mt-5 space-y-3 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 sm:space-y-0">
            <Button
              background={cancelAction.background || 'bg-white'}
              disabled={disabled}
              fullWidth
              loading={loading}
              onClick={() => {
                if (cancelAction.onClick) cancelAction.onClick()
                if (setOpen) setOpen(false)
              }}
              outlined
              label={cancelAction.label}
            />

            <Button
              disabled={disabled}
              fullWidth
              label={submitAction.label}
              loading={loading}
              onClick={() => submitAction.onClick()}
            />
          </div>

          <Button
            background="bg-red"
            disabled={disabled}
            fullWidth
            loading={loading}
            onClick={() => deleteAction.onClick()}
            label={deleteAction.label}
          />
        </>
      )
    }

    if (cancelAction) {
      return (
        <div className="mt-5 space-y-3 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 sm:space-y-0">
          <Button
            background={cancelAction.background || 'bg-white'}
            disabled={disabled}
            fullWidth
            label={cancelAction.label}
            loading={loading}
            onClick={() => {
              if (cancelAction.onClick) cancelAction.onClick()
              if (setOpen) setOpen(false)
            }}
            outlined
          />

          <Button
            background={submitAction.background || 'bg-purple'}
            disabled={submitAction.disabled || disabled}
            fullWidth
            label={submitAction.label}
            loading={loading}
            onClick={submitAction.onClick}
          />
        </div>
      )
    }

    return (
      <div className="mt-5 sm:mt-6">
        <Button
          background={submitAction.background || 'bg-purple'}
          disabled={submitAction.disabled || disabled}
          fullWidth
          onClick={submitAction.onClick}
          label={submitAction.label}
          loading={loading}
        />
      </div>
    )
  }

  /**
   * Renders a Modal Description if one is provided.
   *
   * - If the description is an array, maps out the provided strings with vertical spacing.
   * - Otherwise, renders a single description.
   */
  const renderDescription = () => {
    if (description) {
      if (_.isArray(description))
        return (
          <div className="mt-4 space-y-2">
            {description.map((d) => (
              <Dialog.Description className="text-sm font-normal text-gray-500">
                {d}
              </Dialog.Description>
            ))}
          </div>
        )

      return (
        <Dialog.Description className="mt-4 text-sm font-normal text-gray-500">
          {description}
        </Dialog.Description>
      )
    }

    return null
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (onClose) onClose()
          else if (setOpen) setOpen(false)
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={mergeClassNames(
                  'relative flex w-full flex-col rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6',
                  className || 'sm:max-w-xl',
                )}
              >
                <div className={containerClassName}>
                  <div className="flex flex-row content-center items-center justify-center space-x-2">
                    {icon && (
                      <div
                        className={mergeClassNames(
                          'flex h-12 w-12 items-center justify-center rounded-full',
                          iconBackground,
                        )}
                      >
                        {icon}
                      </div>
                    )}
                    {title && (
                      <DialogTitle className="text-2xl font-bold leading-6 text-gray-900">
                        {title}
                      </DialogTitle>
                    )}
                  </div>

                  {renderDescription()}

                  {content}
                </div>

                <div className="space-y-3">{renderActions()}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.defaultProps = {
  actions: [
    { type: 'cancel', label: 'Cancel' },
    { type: 'submit', label: 'Save' },
  ],
  className: null,
  containerClassName: null,
  description: null,
  disabled: false,
  icon: null,
  iconBackground: 'bg-purple',
  loading: false,
  onClose: null,
  open: false,
  setOpen: null,
  title: null,
}

Modal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  containerClassName: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconBackground: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
}

export default Modal
