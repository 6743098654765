import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { ArrowPathIcon, EyeIcon } from '@heroicons/react/20/solid'
import { AtSymbolIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import _ from 'lodash'
import { CircleDashed } from 'tabler-icons-react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { EventHeader } from '../../components/EventHeader'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { RichTextInput } from '../../components/RichTextInput'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Images
import Email from '../../assets/images/email.svg'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import {
  getEventEmails,
  getEventExhibitorTransactions,
  sendEmail,
  sendEmailPreview,
  updateEvent,
  updateEventEmail,
} from '../../services/events.service'
import { getAttendees } from '../../services/attendees.service'
import { getExhibitors } from '../../services/exhibitors.service'
import { getOrganizationUsers } from '../../services/organizations.service'

// Utils
import colors from '../../utils/colors'
import { handlePagination, toast } from '../../utils/helpers'
import { BASE_RECIPIENT_FIELDS, CONTEXT_MERGE_FIELDS } from '../../utils/constants'

/**
 *
 * EventEmails
 *
 */
const EventEmails = observer(() => {
  // Context
  const { event, setEvent } = useContext(NavigationStoreContext)
  const { isEEUser } = useContext(UserStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [emails, setEmails] = useState([])
  const [editEmail, setEditEmail] = useState(null)
  const [loadingEmail, setLoadingEmail] = useState(null)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewEmail, setPreviewEmail] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const [loadingSend, setLoadingSend] = useState(null)
  const [pendingEmails, setPendingEmails] = useState([])

  // Header
  const [fileLoaded, setFileLoaded] = useState(event?.signedEmailHeaderImage)
  const [uploadHeader, setUploadHeader] = useState(!event?.signedEmailHeaderImage)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [pages, setPages] = useState(null)

  const handleError = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets updated emails for this event using the specified `url`.
   */
  const getUpdatedEmails = async (url) => {
    const response = await getEventEmails(url, setError, setLoading, () => {})

    if (response) {
      setEmails(response.results)
      setTotalRows(response.count)
      setPages(response.paperPages)
    }
  }

  useEffect(() => {
    getUpdatedEmails(`/events/${event.id}/emails/?limit=${perPage}&page=${currentPage}`)
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setError: setFormError,
  } = useForm({
    defaultValues: {
      suject: null,
      body: null,
    },
  })

  const {
    control: previewControl,
    handleSubmit: handlePreviewSubmit,
    formState: { errors: previewErrors },
    getValues,
    register: previewRegister,
    reset: resetPreview,
    setError: setPreviewError,
    watch,
  } = useForm({
    defaultValues: {
      toEmailOverride: null,
      recipient: null,
    },
  })

  useEffect(() => {
    const eventExhibitor = getValues('eventExhibitor')

    if (eventExhibitor) {
      const getExhibitorDependentData = async () => {
        const { options } = previewEmail
        const scannedBy = _.includes(previewEmail.email.availableContext, 'Scanned By')
        const assignedBy = _.includes(previewEmail.email.availableContext, 'Assigned By')
        const transactions = _.includes(previewEmail.email.availableContext, 'Transaction')

        // scannedBy and assignedBy are both exhibitor users
        if (
          scannedBy ||
          assignedBy ||
          previewEmail.email.recipients === 'Exhibitor' ||
          previewEmail.email.recipients === 'Licensees (Dynamic)'
        ) {
          const response = await getOrganizationUsers(
            `/organizations/${eventExhibitor.exhibitorId}/roles/?expand=user&limit=99999`,
            handleError,
            () => {},
            () => {},
          )

          if (response && (scannedBy || assignedBy)) {
            options[scannedBy ? 'Scanned By' : 'Assigned By'] = _.map(response.results, (e) => ({
              id: e.user.id,
              label: `${e.user.firstName} ${e.user.lastName}`,
            }))
          } else if (response) {
            options.recipients = _.map(response.results, (r) => ({
              id: r.user.id,
              label: `${r.user.firstName} ${r.user.lastName}`,
            }))
          }
        }

        // Get transactions for exhibitor
        if (transactions) {
          const response = await getEventExhibitorTransactions(
            event.id,
            eventExhibitor.id,
            handleError,
            () => {},
            () => {},
          )

          if (response) {
            options.Transaction = _.map(response.results, (t) => ({
              id: t.id,
              label: `${t.transId}`,
            }))
          }
        }

        // Get exhibitor users for recipients
        if (previewEmail.email.recipients === 'Exhibitor') {
          const response = await getOrganizationUsers(
            `/organizations/${eventExhibitor.exhibitorId}/roles/?expand=user&limit=99999`,
            handleError,
            () => {},
            () => {},
          )

          if (response) {
            options.recipients = _.map(response.results, (r) => ({
              id: r.user.id,
              label: `${r.user.firstName} ${r.user.lastName}`,
            }))
          }
        }

        setPreviewEmail({ ...previewEmail, options })
      }

      getExhibitorDependentData()
    }
  }, [watch('eventExhibitor')])

  /**
   * Handles submitting the update email form.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    if (data.body === null || data.body === '<p><br></p>') {
      setFormError('body')
      return
    }

    const response = await updateEventEmail(
      event.id,
      { id: editEmail.id, subject: data.subject, body: data.body },
      handleError,
      setLoadingEmail,
      handleSuccess,
    )

    if (response) {
      getUpdatedEmails(`/events/${event.id}/emails/?limit=${perPage}&page=${currentPage}`)

      setEditEmail(null)
      reset()
    }
  }

  /**
   * Renders the send button for the specified `data` and `label`.
   * @param {object} data
   * @param {string} label
   */
  const renderSendButton = (data, label) => (
    <button
      disabled={loadingSend && loadingSend === data.id}
      type="button"
      className={mergeClassNames(
        'px-2 py-0.5 font-bold text-blue-600 hover:rounded-full hover:bg-status-blue disabled:opacity-50',
        label === 'Resend' && 'text-purple-600 hover:bg-status-purple',
      )}
      onClick={async () => {
        setLoadingSend(data.id)
        setPendingEmails([...pendingEmails, data.id])

        await sendEmail(
          event.id,
          data.id,
          label === 'Resend' ? { forceResend: true } : null,
          (e) => {
            handleError(e)
            setLoadingSend(null)
          },
          () => {},
          (m) => {
            handleSuccess(m)
            getUpdatedEmails(`/events/${event.id}/emails/?limit=${perPage}&page=1`)
            setLoadingSend(null)
          },
        )
      }}
    >
      <div className="flex flex-row items-center">
        {loadingSend && loadingSend === data.id && (
          <svg className="mr-1 h-4 w-4 motion-safe:animate-spin-slow" viewBox="0 0 24 24">
            <CircleDashed
              size={24}
              strokeWidth={2}
              color={label === 'Resend' ? colors.purple.DEFAULT : colors.blue.DEFAULT}
            />
          </svg>
        )}
        {label}
      </div>
    </button>
  )

  const renderLoadingOrError = () => {
    if (loading) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-2xl font-bold">Loading...</span>

          <span className="flex items-center pr-3">
            <div className="h-10 w-10">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    if (error) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">Error generating report.</span>
        </div>
      )
    }

    return null
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="mb-3 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />
          </div>

          <div className="mt-1.5 flex flex-col">
            <span className="text-md font-bold">Customize Emails</span>

            <span className="text-md font-bold">Emails</span>
            <span className="mb-2 text-xs">
              Manage emails that will be sent to Exhibitors and Attendees for this event.
            </span>

            {emails && !loading ? (
              <DataTable
                columns={[
                  {
                    id: 'name',
                    grow: 1,
                    name: 'Name',
                    selector: (row) => row.name,
                    cell: (row) => <span className="text-sm font-bold text-dark">{row.name}</span>,
                    minWidth: '280px',
                  },
                  {
                    id: 'type',
                    grow: 0.2,
                    name: 'Type',
                    selector: (row) => row.trigger,
                    cell: (row) => {
                      if (row.trigger === 'Manual - EE Triggered') {
                        return (
                          <div className="rounded-full bg-status-blue px-2.5 py-0.5">
                            <span className="text-xs font-bold text-status-darkBlue">Manual</span>
                          </div>
                        )
                      }

                      return (
                        <div className="rounded-full bg-status-purple px-2.5 py-0.5">
                          <span className="text-xs font-bold text-role-user">Automatic</span>
                        </div>
                      )
                    },
                    center: true,
                    minWidth: '120px',
                  },
                  {
                    id: 'sentAt',
                    grow: 0.5,
                    name: 'Date Sent',
                    selector: (row) => row.sentAt,
                    cell: (row) => {
                      if (row.trigger !== 'Manual - EE Triggered') {
                        return 'N/A'
                      }

                      if (row.sentAt)
                        return (
                          <div className="flex shrink-0 flex-row place-items-center space-x-2">
                            <span>{dayjs(row.sentAt).format('MM/DD/YYYY HH:mm A')}</span>

                            {isEEUser && renderSendButton(row, 'Resend')}
                          </div>
                        )

                      if (isEEUser)
                        return pendingEmails.includes(row.id) ? (
                          <div className="rounded-full bg-success-light px-2.5 py-0.5">
                            <span className="text-xs font-bold text-success">Pending</span>
                          </div>
                        ) : (
                          renderSendButton(row, 'Send')
                        )
                      return 'N/A'
                    },
                    center: true,
                    minWidth: '200px',
                  },
                  {
                    id: 'edit',
                    grow: 0.2,
                    name: '',
                    omit: !isEEUser,
                    cell: (row) => (
                      <button
                        className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
                        type="button"
                        onClick={() => {
                          reset(row)
                          setEditEmail(row)
                        }}
                      >
                        Edit
                      </button>
                    ),
                    center: true,
                    width: '100px',
                  },
                  {
                    id: 'preview',
                    grow: 0.2,
                    name: '',
                    omit: !isEEUser,
                    cell: (row) => (
                      <button
                        className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
                        type="button"
                        onClick={async () => {
                          const preview = {
                            email: row,
                            options: {},
                          }

                          // Request context data to populate preview options
                          await Promise.all(
                            _.map(row.availableContext, async (context) => {
                              if (context === 'Event Exhibitor') {
                                const response = await getExhibitors(
                                  `/events/${event.id}/exhibitors/?expand=exhibitor,limit=99999`,
                                  handleError,
                                  () => {},
                                  () => {},
                                )

                                if (response) {
                                  preview.options[context] = _.map(response.results, (e) => ({
                                    id: e.id,
                                    exhibitorId: e.exhibitor.id,
                                    label: e.exhibitor.name,
                                  }))
                                }
                              } else if (context === 'Attendee') {
                                const response = await getAttendees(
                                  `/events/${event.id}/attendees/?limit=99999`,
                                  handleError,
                                  () => {},
                                  () => {},
                                )

                                if (response) {
                                  preview.options[context] = _.map(response.results, (a) => ({
                                    id: a.id,
                                    label: `${a.firstName} ${a.lastName}`,
                                  }))
                                }
                              }
                            }),
                          )

                          if (
                            row.recipients === 'Attendee' ||
                            row.recipients === 'Attendees (Dynamic)'
                          ) {
                            const response = await getAttendees(
                              `/events/${event.id}/attendees/?expand=user&limit=99999`,
                              handleError,
                              () => {},
                              () => {},
                            )

                            if (response) {
                              preview.options.recipients = _.map(response.results, (a) => ({
                                id: a.id,
                                label: `${a.firstName} ${a.lastName}`,
                              }))
                            }
                          }

                          setPreviewEmail(preview)
                          setShowPreviewModal(true)
                        }}
                      >
                        Preview
                      </button>
                    ),
                    center: true,
                    minWidth: '110px',
                  },
                ]}
                data={emails}
                onChangePage={(page) =>
                  handlePagination(
                    page,
                    currentPage,
                    perPage,
                    totalRows,
                    pages,
                    setCurrentPage,
                    getUpdatedEmails,
                    `/events/${event.id}/emails/?limit=`,
                  )
                }
                onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
                pagination
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
                paginationTotalRows={totalRows}
                paginationServer
                progressPending={loading}
              />
            ) : (
              renderLoadingOrError()
            )}

            <span className="text-md font-bold">Event Header</span>
            <span className="mb-2 text-xs">
              Header image for Attendee emails (1200px x 110px). Default header will be Eventstack
              branding.
            </span>

            {event?.signedEmailHeaderImage && !uploadHeader ? (
              <div className="space-y-2">
                <Button
                  className="text-xs"
                  label="Replace"
                  onClick={() => {
                    setUploadHeader(true)
                    setFileLoaded(true)
                  }}
                />

                <img className="w-full" src={event.signedEmailHeaderImage} alt="Email Header" />
              </div>
            ) : (
              uploadHeader && (
                <FileUploader
                  acceptedFileTypes={['image/*']}
                  allowResize
                  allowRevert
                  fileLoaded={fileLoaded}
                  handleUploadToServer={async (file) => {
                    setUploadHeader(false)
                    const updatedEvent = await updateEvent(
                      { id: event.id, emailHeaderImage: file.url },
                      handleError,
                      () => {},
                      handleSuccess,
                    )

                    setEvent(updatedEvent)
                  }}
                  isPublic
                  id="emailHeaderImage"
                  imageCropAspectRatio="1:1"
                  setFileLoaded={(loaded) => {
                    if (event.signedEmailHeaderImage && !loaded) {
                      setUploadHeader(false)
                    } else {
                      setFileLoaded(loaded)
                    }
                  }}
                  type="gcp"
                  whiteBackground
                />
              )
            )}
          </div>
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setEditEmail(null),
          },
          {
            type: 'submit',
            label: 'Update Email',
            onClick: handleSubmit(onSubmit),
          },
        ]}
        icon={<AtSymbolIcon className="h-8 stroke-white" />}
        containerClassName="h-[90%]"
        content={
          <div className="flex h-full w-full flex-row space-x-4 py-3 text-center sm:py-5">
            <div className="flex w-3/4 flex-col space-y-4">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="subject"
                disabled={loadingEmail}
                error={errors.subject && 'This field is required'}
                fullWidth
                id="subject"
                inputStyles="rounded-none"
                name="subject"
                nunito
                label="Subject"
                placeholder="Subject"
                {...register('subject', { required: true })}
              />

              <div className="flex flex-col justify-start tall:h-[90%] short:h-[80%]">
                <label
                  htmlFor="body"
                  className="w-fit pb-1 font-nunito text-sm font-medium text-gray-700"
                >
                  Body
                </label>

                <Controller
                  name="body"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="tall:h-[95%] short:h-[90%]">
                      <RichTextInput
                        className="sm:h-full"
                        historyEnabled
                        id="body"
                        inputStyles="tall:h-[95%] short:h-[90%]"
                        disabled={loadingEmail}
                        onChange={onChange}
                        placeholder="Body"
                        value={value}
                      />

                      {errors.body && (
                        <div className="mt-1 w-full bg-error-light px-2 py-1">
                          <p className="text-sm font-medium text-error-dark" id="error-rte">
                            This field is required
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="h-[95%] w-[1px] bg-black" />

            <div className="flex h-full w-1/4 flex-col">
              <span className="w-fit pb-1 font-nunito text-sm font-medium text-gray-700">
                Merge Fields
              </span>

              {editEmail?.availableContext?.length > 0 ? (
                _.map(
                  _.concat(
                    BASE_RECIPIENT_FIELDS,
                    _.flatMap(editEmail?.availableContext, (c) => CONTEXT_MERGE_FIELDS[c]),
                  ),
                  (f) => (
                    <button
                      className="group flex cursor-pointer flex-row"
                      key={`${f}`}
                      onClick={() => {
                        navigator.clipboard.writeText(`{{ ${f} }}`)
                        handleSuccess('Merge field copied to clipboard!')
                      }}
                      type="button"
                    >
                      <span className="group-hover:text-purple">{f}</span>

                      <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-black" />
                    </button>
                  ),
                )
              ) : (
                <span className="self-start text-sm text-gray-800">
                  No Merge Fields Available.
                </span>
              )}
            </div>
          </div>
        }
        className="sm:h-[90vh] sm:max-h-[1200px] sm:w-[90vw] sm:max-w-screen-xl"
        loading={loadingEmail}
        open={!!editEmail}
        title="Update Email"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              resetPreview()
              setPreviewEmail(null)
              setShowPreviewModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Send Preview',
            onClick: handlePreviewSubmit(async (data) => {
              if (data.recipient === null) {
                setPreviewError('recipient')
                return
              }

              const payload = {
                toEmailOverride: data.toEmailOverride,
              }
              const recipient = data.recipient.id

              // eslint-disable-next-line no-param-reassign
              delete data.toEmailOverride
              // eslint-disable-next-line no-param-reassign
              delete data.recipient
              payload.additionalContextVars = _.mapValues(data, (v) => v?.id || null)

              if (
                previewEmail.email.recipients === 'Attendee' ||
                previewEmail.email.recipients === 'Attendees (Dynamic)'
              ) {
                payload.additionalContextVars.attendee = recipient
              } else {
                payload.recipient = recipient
              }

              await sendEmailPreview(
                event.id,
                previewEmail.email.id,
                payload,
                handleError,
                setLoadingPreview,
                (m) => {
                  handleSuccess(m)
                  resetPreview()
                  setShowPreviewModal(false)
                },
              )
            }),
          },
        ]}
        icon={<EyeIcon className="h-5 fill-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 sm:mt-5">
            <div className="flex flex-col justify-center space-y-2">
              <span className="self-center text-center text-lg font-bold">
                {previewEmail?.email.name}
              </span>

              {previewEmail?.email.identifier === 'attendees-scan-and-go' && (
                <span className="self-center text-center text-sm italic text-gray-900">
                  Attendee QR code will be attached to the email.
                </span>
              )}
            </div>

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              error={previewErrors.toEmailOverride && 'This field is required'}
              icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
              inputStyles="rounded-none rounded-t-md font-nunito"
              label="Recipient Override"
              name="toEmailOverride"
              placeholder="Enter Recipient Override"
              fullWidth
              {...previewRegister('toEmailOverride', { required: true })}
            />

            {previewEmail?.options.recipients && (
              <Controller
                name="recipient"
                control={previewControl}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    disabled={loadingPreview || !previewEmail?.options.recipients}
                    error={previewErrors.recipient && 'This field is required'}
                    fullWidth
                    id="recipient"
                    name="recipient"
                    nunito
                    onChange={onChange}
                    options={previewEmail.options.recipients || []}
                    label="Recipient"
                    placeholder="Select Recipient"
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
            )}

            {_.map(
              _.filter(previewEmail?.email.availableContext, (c) => previewEmail.options[c]),
              (context) => (
                <Controller
                  name={_.camelCase(context)}
                  control={previewControl}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      disabled={loadingPreview || !previewEmail?.options[context]}
                      error={previewErrors[_.camelCase(context)] && 'This field is required'}
                      fullWidth
                      id={_.camelCase(context)}
                      name={_.camelCase(context)}
                      nunito
                      onChange={onChange}
                      options={previewEmail.options[context] || []}
                      label={context}
                      placeholder={`Select ${context}`}
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: context === 'Event Exhibitor' }}
                />
              ),
            )}
          </div>
        }
        loading={loadingPreview}
        open={showPreviewModal}
        title="Preview Email"
      />
    </div>
  )
})

export default EventEmails
