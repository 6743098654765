import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@headlessui/react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 * Toggle Component
 * - Displays a toggle switch
 */
const Toggle = forwardRef(
  ({ className, disabled, id, inter, label, labelClassName, checked, onChange, ...rest }, ref) => (
    <Switch.Group as="div" className={mergeClassNames('flex items-center space-x-2', className)}>
      <Switch.Label
        as="span"
        className={mergeClassNames(
          'text-md font-semibold leading-6 text-black-light',
          !inter && 'font-nunito',
          labelClassName,
        )}
        passive
      >
        {label}
      </Switch.Label>

      <Switch
        data-testid={id}
        checked={Boolean(checked)}
        disabled={disabled}
        onChange={onChange}
        className={joinClassNames(
          checked ? 'bg-purple-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50',
        )}
        ref={ref}
        {...rest}
      >
        <span
          className={joinClassNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <span
            className={joinClassNames(
              checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-gray-500" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={joinClassNames(
              checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-gray-500" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </Switch.Group>
  ),
)

Toggle.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
  id: null,
  inter: false,
  labelClassName: null,
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inter: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Toggle
