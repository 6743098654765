import React, { useContext, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import dayjs from 'dayjs'

// Components
import { Button } from '../Button'
import { CogIcon } from '../CogIcon'
import { DatePicker } from '../DatePicker'
import { DownloadIcon } from '../DownloadIcon'

// Images
import BadgrGray from '../../assets/images/badgr_gray.svg'
import BadgrTextLogo from '../../assets/images/badgr_text_logo.svg'
import EventstackLogo from '../../assets/images/eventstack_logo.svg'
import FetchGray from '../../assets/images/fetch_gray.svg'
import FetchTextLogo from '../../assets/images/fetch_text_logo.svg'
import ReportHeader from '../../assets/images/report_header.svg'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils & Service
import { toast } from '../../utils/helpers'
import colors from '../../utils/colors'

const CustomTick = ({ value, x, y }) => {
  if (value === 'Hot')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥳
        </text>
      </g>
    )
  if (value === 'Warm')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🤔
        </text>
      </g>
    )
  if (value === 'Cold')
    return (
      <g transform={`translate(${x - 15},${y + 32})`}>
        <text x="0" y="0" fontSize="30px">
          🥶
        </text>
      </g>
    )
  return null
}

CustomTick.propTypes = {
  value: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

const Reports = ({
  badgrReportTitle,
  badgrReportSubtitle,
  fetchReportTitle,
  fetchReportSubtitle,
  getReport,
  createExcelReport,
  reportId,
}) => {
  // Context
  const { setParent, setType, setTask } = useContext(TaskStoreContext)

  // State
  const [printing, setPrinting] = useState(false)

  // Summary
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [summaryStartDate, setSummaryStateDate] = useState(null)
  const [summaryEndDate, setSummaryEndDate] = useState(false)

  // Fetch Summary
  const [loadingFetch, setLoadingFetch] = useState(false)
  const [fetchStartDate, setFetchStartDate] = useState(null)
  const [fetchEndDate, setFetchEndDate] = useState(false)
  const [fetchReportData, setFetchReportData] = useState(null)

  // Badgr Summary
  const [loadingBadgr, setLoadingBadgr] = useState(false)
  const [badgrStartDate, setBadgrStartDate] = useState(null)
  const [badgrEndDate, setBadgrEndDate] = useState(false)
  const [badgrReportData, setBadgrReportData] = useState(null)

  // Ref
  const fetchReportRef = useRef()
  const badgrReportRef = useRef()

  const handleError = (message) => toast(message, 'error')

  const handlePrintFetch = useReactToPrint({
    content: () => fetchReportRef.current,
    onAfterPrint: () => setPrinting(false),
  })

  const handlePrintBadgr = useReactToPrint({
    content: () => badgrReportRef.current,
    onAfterPrint: () => setPrinting(false),
  })

  const {
    control: summaryControl,
    handleSubmit: handleSummarySubmit,
    formState: { isValid: summaryIsValid },
    reset: resetSummary,
  } = useForm({
    defaultValues: {
      startsAt: null,
      endsAt: null,
    },
  })

  const {
    control: fetchControl,
    handleSubmit: handleFetchSubmit,
    formState: { errors: fetchErrors, isValid: fetchIsValid },
    getValues: getFetchValues,
  } = useForm({
    defaultValues: {
      startsAt: null,
      endsAt: null,
    },
  })

  const {
    control: badgrControl,
    handleSubmit: handleBadgrSubmit,
    formState: { errors: badgrErrors, isValid: badgrIsValid },
    getValues: getBadgrValues,
  } = useForm({
    defaultValues: {
      startsAt: null,
      endsAt: null,
    },
  })

  /**
   * Configures a data tile using the specified `label` and `value` and optionally `width`.
   * @param {string} label
   * @param {string} value
   */
  const configureTile = (label, value, styles = '', width = '') => (
    <div
      className={mergeClassNames(
        'flex flex-col items-center rounded-lg p-4',
        printing === 'fetch' && 'py-5',
        styles,
        width,
      )}
      style={{
        background: 'linear-gradient(135deg, #000000 0%, #4E259D 50%, #A071FC 100%)',
      }}
    >
      <span
        className={mergeClassNames('text-sm font-semibold', printing === 'fetch' && 'text-md')}
      >
        {label}
      </span>
      <span className={mergeClassNames('text-2xl font-bold', printing && 'text-3xl')}>
        {value}
      </span>
    </div>
  )

  return (
    <>
      <div className="flex flex-row items-center space-x-1">
        <CogIcon className="w-4 fill-black" />
        <span className="text-md font-bold">Reports</span>
      </div>

      {createExcelReport && (
        <>
          <span className="text-xs">Download event data as Excel files or PDFs.</span>

          <span className="text-md mt-4 font-bold">Excel Files</span>

          <div className="flex flex-row items-center justify-between gap-6">
            <span className="text-sm font-semibold">
              Software Wide Summary Data Report (Fetch & Badgr)
            </span>

            <div className="flex w-1/2 flex-row gap-8">
              <Controller
                name="startsAt"
                control={summaryControl}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={loadingSummary}
                    name="startsAt"
                    onChange={onChange}
                    placeholder="Start Date"
                    show={summaryStartDate}
                    setShow={setSummaryStateDate}
                    value={value}
                  />
                )}
                rules={{
                  required: true,
                  validate: (value, values) => {
                    if (values.endsAt && dayjs(value).isAfter(dayjs(values.endsAt))) {
                      return 'Start date must be before end date'
                    }

                    return null
                  },
                }}
              />

              <Controller
                name="endsAt"
                control={summaryControl}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={loadingSummary}
                    name="endsAt"
                    onChange={onChange}
                    placeholder="End Date"
                    show={summaryEndDate}
                    setShow={setSummaryEndDate}
                    value={value}
                  />
                )}
                rules={{
                  required: true,
                  validate: (value, values) => {
                    if (values.startsAt && dayjs(value).isBefore(dayjs(values.startsAt))) {
                      return 'End date must be after start date'
                    }

                    return null
                  },
                }}
              />
            </div>

            <Button
              className="text-xs"
              disabled={!summaryIsValid}
              icon={<DownloadIcon className="h-5 stroke-white sm:h-6" />}
              label="Download"
              loading={loadingSummary}
              onClick={handleSummarySubmit(async (data) => {
                const startDate = dayjs(data.startsAt).format('YYYY-MM-DD')
                const endDate = dayjs(data.endsAt).format('YYYY-MM-DD')

                const task = await createExcelReport(
                  {
                    startDate,
                    endDate,
                  },
                  handleError,
                  setLoadingSummary,
                )

                if (task) {
                  setTimeout(() => {
                    resetSummary({
                      startsAt: null,
                      endsAt: null,
                    })
                  }, 500)

                  setType('Download')
                  setParent({
                    type: 'admin-summary',
                    label: 'Summary Report',
                    id: task.id,
                    fileName: `Event_Summary_${startDate}_${endDate}`,
                  })
                  setTask(task)
                }
              })}
            />
          </div>

          <span className="text-md mt-4 font-bold">PDF Reports</span>
        </>
      )}

      {/* Fetch Report */}

      <div className="mb-2 flex flex-row items-center justify-between">
        <span className="text-sm font-semibold">{fetchReportSubtitle} - Fetch</span>

        <Button
          disabled={!fetchIsValid}
          label="Print Fetch Report"
          onClick={() => {
            setPrinting('fetch')

            setTimeout(() => {
              handlePrintFetch()
            }, 200)
          }}
        />
      </div>

      <div className="mb-6 flex flex-row items-center justify-center gap-8">
        <div className="flex w-1/2 flex-row gap-8">
          <Controller
            name="startsAt"
            control={fetchControl}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                disabled={loadingFetch}
                error={
                  fetchErrors.startsAt &&
                  (fetchErrors.startsAt.message || 'This field is required')
                }
                name="startsAt"
                onChange={onChange}
                placeholder="Start Date"
                show={fetchStartDate}
                setShow={setFetchStartDate}
                value={value}
              />
            )}
            rules={{
              required: true,
              validate: (value, values) => {
                if (values.endsAt && dayjs(value).isAfter(dayjs(values.endsAt))) {
                  return 'Start date must be before end date'
                }

                return null
              },
            }}
          />

          <Controller
            name="endsAt"
            control={fetchControl}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                disabled={loadingFetch}
                error={
                  fetchErrors.endsAt && (fetchErrors.endsAt.message || 'This field is required')
                }
                name="endsAt"
                onChange={onChange}
                placeholder="End Date"
                show={fetchEndDate}
                setShow={setFetchEndDate}
                value={value}
              />
            )}
            rules={{
              required: true,
              validate: (value, values) => {
                if (values.startsAt && dayjs(value).isBefore(dayjs(values.startsAt))) {
                  return 'End date must be after start date'
                }

                return null
              },
            }}
          />
        </div>

        <Button
          className="flex-none"
          disabled={loadingFetch}
          label="Generate Report"
          loading={loadingFetch}
          onClick={handleFetchSubmit(async (data) => {
            // Clear the previous report data
            setFetchReportData(null)

            const reportData = await getReport(
              {
                orgId: reportId,
                type: 'fetch',
                startDate: dayjs(data.startsAt).format('YYYY-MM-DD'),
                endDate: dayjs(data.endsAt).format('YYYY-MM-DD'),
              },
              handleError,
              setLoadingFetch,
            )

            if (reportData) {
              // Convert lead data into the proper format
              const leadsByTemperature = _.map(reportData.leadsByTemperature, (value, key) => {
                let color = colors.purple.DEFAULT
                let temperature = 'Warm'
                if (key === 'great') {
                  color = colors.teal.DEFAULT
                  temperature = 'Hot'
                }
                if (key === 'cold') {
                  color = colors.blue.DEFAULT
                  temperature = 'Cold'
                }

                return {
                  temperature,
                  value,
                  color,
                }
              })

              setFetchReportData({ ...reportData, leadsByTemperature })
            }
          })}
        />
      </div>

      {fetchReportData && (
        <div className="relative max-w-[1200px] bg-white" ref={fetchReportRef}>
          <img
            alt="logo"
            className="absolute inset-x-auto top-32 z-0 h-[85%] w-full"
            src={FetchGray}
          />

          <div
            className={mergeClassNames('flex flex-col gap-10', printing === 'fetch' && 'gap-14')}
          >
            <div
              className="z-10 flex flex-row items-center justify-between px-10 py-4"
              style={{
                backgroundImage: `url(${ReportHeader})`,
                backgroundSize: 'cover',
              }}
            >
              <img className="block h-auto w-60" src={EventstackLogo} alt="Eventstack" />
              <img className="block h-auto w-36" src={FetchTextLogo} alt="Fetch" />
            </div>

            <div className="z-10 flex flex-col items-center">
              <span className="text-3xl font-bold">{fetchReportTitle}</span>
              <span className="text-2xl">{fetchReportSubtitle}</span>
              <span className="text-lg text-gray-600">
                Date Range: {dayjs(getFetchValues().startsAt).format('MM/DD/YYYY')} -{' '}
                {dayjs(getFetchValues().endsAt).format('MM/DD/YYYY')}
              </span>
            </div>

            <div className="z-10 flex flex-row gap-8 px-6 text-center text-white">
              {configureTile(
                'Total Fetch Events',
                fetchReportData.eventsWithLeadRetrieval.toLocaleString(),
                'w-1/3',
              )}
              {configureTile(
                'Total Licenses Purchased',
                fetchReportData.appLicenseCount.toLocaleString(),
                'w-1/3',
              )}
              {configureTile(
                'Total Leads Scanned',
                fetchReportData.totalLeadCount.toLocaleString(),
                'w-1/3',
              )}
            </div>

            <div className="z-10 flex flex-row justify-center gap-8 text-center text-white">
              {configureTile(
                'Total Exhibiting Companies',
                fetchReportData.totalExhibitingCompanies.toLocaleString(),
                'w-1/3',
              )}
              {configureTile(
                'Total Revenue from Fetch',
                fetchReportData.totalRevenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                'w-1/3',
              )}
            </div>

            <div
              className={mergeClassNames(
                'z-10 flex w-full flex-col gap-6 px-8 sm:flex-row',
                printing === 'fetch' && 'px-8',
              )}
            >
              <div className="flex w-full flex-col gap-3 rounded-xl bg-purple-dark px-4 py-6">
                <span className="text-2xl font-bold text-white">Leads Breakdown</span>

                <div className="flex h-44 w-full flex-row justify-between gap-6">
                  <div className="flex h-full shrink-0 flex-col justify-around">
                    {_.map(fetchReportData.leadsByTemperature, (data) => (
                      <div className="flex flex-row items-center gap-1">
                        <div className="h-4 w-4 rounded-sm" style={{ background: data.color }} />
                        <span className="wrap text-xs font-medium text-white">
                          {data.value.toLocaleString()} {data.temperature}
                        </span>
                      </div>
                    ))}
                  </div>

                  <div className="h-full w-[230px]">
                    <ResponsiveBar
                      axisBottom={{
                        renderTick: CustomTick,
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisLeft={null}
                      borderRadius="8px"
                      colors={({ data }) => data.color}
                      data={fetchReportData.leadsByTemperature}
                      enableLabel={false}
                      enableGridX={false}
                      indexBy="temperature"
                      isInteractive={false}
                      keys={['value']}
                      margin={{ top: 0, right: 20, bottom: 35, left: 0 }}
                      padding={0.4}
                      theme={{
                        axis: {
                          ticks: {
                            text: {
                              fill: '#96959E',
                              fontSize: '12px',
                              fontWeight: '500',
                            },
                          },
                        },
                        grid: {
                          line: {
                            stroke: '#F3F4F6',
                            strokeOpacity: 0.2,
                            strokeWidth: 1,
                            strokeDasharray: '6',
                          },
                        },
                      }}
                      valueScale={{ type: 'linear' }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col gap-3 rounded-xl bg-blue-200 px-4 py-6">
                <span className="text-2xl font-bold text-dark">Purchase Breakdown</span>

                <div className="flex h-44 w-full flex-row gap-16">
                  <div className="flex h-full shrink-0 flex-col justify-start gap-5">
                    <div className="flex flex-col justify-center">
                      <span className="font-medium text-purple">
                        {fetchReportData.appLicenseCount.toLocaleString()}
                      </span>
                      <span className="font-medium text-dark">Apps</span>
                    </div>
                    <div className="flex flex-col justify-center">
                      <span className="font-medium text-blue">
                        {fetchReportData.rentalDeviceCount.toLocaleString()}
                      </span>
                      <span className="font-medium text-dark">Devices</span>
                    </div>
                  </div>

                  <div className="flex h-full w-[200px]">
                    <ResponsivePie
                      colors={{ datum: 'data.color' }}
                      data={[
                        {
                          color: colors.purple.DEFAULT,
                          id: 'apps',
                          value: fetchReportData.appLicenseCount,
                          label: 'Apps',
                        },
                        {
                          color: colors.blue.DEFAULT,
                          id: 'devices',
                          value: fetchReportData.rentalDeviceCount,
                          label: 'Devices',
                        },
                      ]}
                      innerRadius={0.6}
                      isInteractive={false}
                      enableArcLabels={false}
                      enableArcLinkLabels={false}
                      layers={['arcs']}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <span
              className={mergeClassNames(
                'z-10 mx-6 mt-10 block text-center text-xs text-gray-500',
                printing === 'fetch' && 'block',
              )}
            >
              CONFIDENTIALITY NOTICE: This document is for the sole use of the intended
              recipient(s) and may contain confidential and privileged information. Any
              unauthorized review, use, disclosure or distribution is prohibited.
            </span>
          </div>
        </div>
      )}

      {/* Badgr Report */}

      <div className="mb-2 flex flex-row items-center justify-between">
        <span className="text-sm font-semibold">{badgrReportSubtitle} - Badgr</span>

        <Button
          disabled={!badgrIsValid}
          label="Print Badgr Report"
          onClick={() => {
            setPrinting('badgr')

            setTimeout(() => {
              handlePrintBadgr()
            }, 200)
          }}
        />
      </div>

      <div className="mb-6 flex flex-row items-center justify-center gap-8">
        <div className="flex w-1/2 flex-row gap-8">
          <Controller
            name="startsAt"
            control={badgrControl}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                disabled={loadingBadgr}
                error={
                  badgrErrors.startsAt &&
                  (badgrErrors.startsAt.message || 'This field is required')
                }
                name="startsAt"
                onChange={onChange}
                position={fetchReportData && !badgrReportData && 'bottom-14 top-auto'}
                placeholder="Start Date"
                show={badgrStartDate}
                setShow={setBadgrStartDate}
                value={value}
              />
            )}
            rules={{
              required: true,
              validate: (value, values) => {
                if (values.endsAt && dayjs(value).isAfter(dayjs(values.endsAt))) {
                  return 'Start date must be before end date'
                }

                return null
              },
            }}
          />

          <Controller
            name="endsAt"
            control={badgrControl}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                disabled={loadingBadgr}
                error={
                  badgrErrors.endsAt && (badgrErrors.endsAt.message || 'This field is required')
                }
                name="endsAt"
                onChange={onChange}
                placeholder="End Date"
                position={fetchReportData && !badgrReportData && 'bottom-14 top-auto'}
                show={badgrEndDate}
                setShow={setBadgrEndDate}
                value={value}
              />
            )}
            rules={{
              required: true,
              validate: (value, values) => {
                if (values.startsAt && dayjs(value).isBefore(dayjs(values.startsAt))) {
                  return 'End date must be after start date'
                }

                return null
              },
            }}
          />
        </div>

        <Button
          className="flex-none"
          disabled={loadingBadgr}
          label="Generate Report"
          loading={loadingBadgr}
          onClick={handleBadgrSubmit(async (data) => {
            // Clear the previous report data
            setBadgrReportData(null)

            const reportData = await getReport(
              {
                orgId: reportId,
                type: 'badgr',
                startDate: dayjs(data.startsAt).format('YYYY-MM-DD'),
                endDate: dayjs(data.endsAt).format('YYYY-MM-DD'),
              },
              handleError,
              setLoadingBadgr,
            )

            const percentageCheckedIn = `${
              (reportData.totalAttendeeCount / reportData.totalLeadCount || 0) * 100
            }%`

            if (reportData) {
              setBadgrReportData({
                ...reportData,
                percentageCheckedIn,
              })
            }
          })}
        />
      </div>

      {badgrReportData && (
        <div className="relative max-w-[1200px] bg-white" ref={badgrReportRef}>
          <img
            alt="logo"
            className="absolute inset-x-auto top-32 z-0 h-[85%] w-full"
            src={BadgrGray}
          />

          <div
            className={mergeClassNames('flex flex-col gap-10', printing === 'badgr' && 'gap-14')}
          >
            <div
              className="z-10 flex flex-row items-center justify-between px-10 py-4"
              style={{
                backgroundImage: `url(${ReportHeader})`,
                backgroundSize: 'cover',
              }}
            >
              <img className="block h-auto w-60" src={EventstackLogo} alt="Eventstack" />
              <img className="block h-auto w-36" src={BadgrTextLogo} alt="Badgr" />
            </div>

            <div className="z-10 flex flex-col items-center">
              <span className="text-3xl font-bold">{badgrReportTitle}</span>
              <span className="text-2xl">{badgrReportSubtitle}</span>
              <span className="text-lg text-gray-600">
                Date Range: {dayjs(getBadgrValues().startsAt).format('MM/DD/YYYY')} -{' '}
                {dayjs(getBadgrValues().endsAt).format('MM/DD/YYYY')}
              </span>
            </div>

            <div className="z-10 flex flex-row gap-8 px-6 text-center text-white">
              <div className="flex w-2/5 flex-col justify-between gap-8">
                {configureTile(
                  'Total Badgr Events',
                  badgrReportData.eventsWithBadging.toLocaleString(),
                  'flex-1 justify-center',
                )}

                {configureTile(
                  'Total Registrants',
                  badgrReportData.totalAttendeeCount.toLocaleString(),
                  'flex-1 justify-center',
                )}

                {configureTile(
                  'Percentage (%) Checked-in',
                  badgrReportData.percentageCheckedIn,
                  'flex-1 justify-center',
                )}

                {configureTile(
                  'Total Registration Locations',
                  badgrReportData.totalCheckInLocations.toLocaleString(),
                  'flex-1 justify-center',
                )}
              </div>

              <div className="flex w-3/5 flex-col justify-between gap-8">
                {/* 'Attendance Breakdown' */}
                <div className="flex h-[268px] w-full flex-col justify-between gap-12 rounded-xl bg-purple-dark px-4 py-6">
                  <span className="text-left text-2xl font-bold text-white">
                    Attendance Breakdown
                  </span>

                  <div className="flex w-full flex-1 flex-row justify-between">
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-row items-center gap-1">
                        <div
                          className="h-4 w-4 rounded-sm"
                          style={{ background: colors.teal.DEFAULT }}
                        />
                        <span className="wrap text-xs font-medium text-white">Checked-In</span>
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <div
                          className="h-4 w-4 rounded-sm"
                          style={{ background: colors.purple.DEFAULT }}
                        />
                        <span className="wrap text-xs font-medium text-white">No-Shows</span>
                      </div>
                    </div>

                    <div className={mergeClassNames(printing ? 'w-[250px]' : 'w-3/5')}>
                      <ResponsiveBar
                        axisBottom={{
                          tickSize: 0,
                          tickPadding: 10,
                          format: (value) => value.toLocaleString(),
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={null}
                        borderRadius="8px"
                        colors={({ data }) => data.color}
                        data={[
                          {
                            attendees: 'Checked-In',
                            value: badgrReportData.checkedInAttendeeCount,
                            color: '#6EE5CD',
                          },
                          {
                            attendees: 'No-Shows',
                            value: badgrReportData.notCheckedInAttendeeCount,
                            color: '#8C56F6',
                          },
                        ]}
                        enableLabel={false}
                        enableGridX={false}
                        indexBy="value"
                        isInteractive={false}
                        keys={['value']}
                        margin={{ top: 0, right: 20, bottom: 35, left: 0 }}
                        padding={0.4}
                        theme={{
                          axis: {
                            ticks: {
                              text: {
                                fill: '#fff',
                                fontSize: '12px',
                                fontWeight: '700',
                              },
                            },
                          },
                          grid: {
                            line: {
                              stroke: '#F3F4F6',
                              strokeOpacity: 0.2,
                              strokeWidth: 1,
                              strokeDasharray: '6',
                            },
                          },
                        }}
                        valueScale={{ type: 'linear' }}
                      />
                    </div>
                  </div>
                </div>

                {/* 'Data Source Breakdown' */}
                <div className="flex w-full flex-col gap-3 rounded-xl bg-blue-200 px-4 py-6">
                  <span className="text-left text-2xl font-bold text-dark">
                    Data Source Breakdown
                  </span>

                  <div className="flex h-44 w-full flex-row justify-between gap-16">
                    <div className="flex h-full shrink-0 flex-col justify-start gap-5">
                      <div className="flex flex-col justify-center text-left">
                        <span className="font-medium text-purple">
                          {badgrReportData.eventsWithRegistrationSync.toLocaleString()}
                        </span>
                        <span className="font-medium text-dark">API</span>
                      </div>
                      <div className="flex flex-col justify-center text-left">
                        <span className="font-medium text-blue">
                          {badgrReportData.eventsWithoutRegistrationSync.toLocaleString()}
                        </span>
                        <span className="font-medium text-dark">Upload</span>
                      </div>
                    </div>

                    <div className="flex h-full w-[200px]">
                      <ResponsivePie
                        colors={{ datum: 'data.color' }}
                        data={[
                          {
                            color: colors.purple.DEFAULT,
                            id: 'api',
                            value: badgrReportData.eventsWithRegistrationSync,
                            label: 'API',
                          },
                          {
                            color: colors.blue.DEFAULT,
                            id: 'upload',
                            value: badgrReportData.eventsWithoutRegistrationSync,
                            label: 'Upload',
                          },
                        ]}
                        innerRadius={0.6}
                        isInteractive={false}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        layers={['arcs']}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span
              className={mergeClassNames(
                'z-10 mx-6 mt-10 block text-center text-xs text-gray-500',
                printing === 'badgr' && 'block',
              )}
            >
              CONFIDENTIALITY NOTICE: This document is for the sole use of the intended
              recipient(s) and may contain confidential and privileged information. Any
              unauthorized review, use, disclosure or distribution is prohibited.
            </span>
          </div>
        </div>
      )}
    </>
  )
}

Reports.defaultProps = {
  badgrReportTitle: 'Eventstack',
  badgrReportSubtitle: 'Software Wide Summary Report',
  fetchReportTitle: 'EventStack',
  fetchReportSubtitle: 'Software Wide Summary Report',
  createExcelReport: null,
  reportId: '',
}

Reports.propTypes = {
  badgrReportTitle: PropTypes.string,
  badgrReportSubtitle: PropTypes.string,
  fetchReportTitle: PropTypes.string,
  fetchReportSubtitle: PropTypes.string,
  getReport: PropTypes.func.isRequired,
  createExcelReport: PropTypes.func,
  reportId: PropTypes.string,
}

export default Reports
