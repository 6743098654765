// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the admin report data using the specified `payload`.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getAdminReport = async (payload, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/events/report-kpis?type=${payload.type}&starts_at_before=${payload.endDate}&ends_at_after=${payload.startDate}`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the event report data using the specified `payload`.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getOrganizationReport = async (
  payload,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/organizations/${payload.orgId}/events/report-kpis?type=${payload.type}&starts_at_before=${payload.endDate}&ends_at_after=${payload.startDate}`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the event report data using the specified `payload`.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getEventReport = async (payload, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/organizations/${payload.orgId}/events/${payload.eventId}/report-kpis?type=${payload.type}`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the event report data using the specified `payload`.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getEventExhibitorReport = async (
  eventId,
  eventExhibitorId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/exhibitors/${eventExhibitorId}/report-kpis`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates an admin summary report using the specified `paylaod`.
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const createAdminSummaryReport = async (
  payload,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/event_exports/?starts_at_before=${payload.startDate}&ends_at_after=${payload.endDate}`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the status of the admin summary export.
 * @param {string} taskId
 * @param {func} setError
 * @param {func} setLoading
 * @returns status
 */
export const getAdminSummaryExportStatus = async (
  taskId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/event_exports/${taskId}`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates an event transaction report for the specified `eventId`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 */
export const createEventTransactionReport = async (
  eventId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/events/${eventId}/event-transaction-exports/`)

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the event transaction report status for the specified `exportId`.
 * @param {string} eventId
 * @param {string} exportId
 * @param {func} setError
 * @param {func} setLoading
 */
export const getEventTransactionReportStatus = async (
  eventId,
  exportId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/events/${eventId}/event-transaction-exports/${exportId}`)

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
